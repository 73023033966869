<template>
  <base-wrapper :loading="loading">
    <div class="space-y-4">
      <div class="flex justify-end gap-2">
        <base-select
          :shadow="false"
          :expand="false"
          :options="[
            { key: 'none', value: null, label: 'Pilih Kantor Tujuan' },
            { key: 'pusat', value: 1, label: 'Pusat' },
            { key: 'kantor_pelayanan', value: 2, label: 'Kantor Pelayanan' },
            { key: 'stockist_center', value: 3, label: 'Stockist Center' },
          ]"
          v-model="filter.origin_office_category_id"
          @change="loadOrders"
        />
        <base-select
          :shadow="false"
          :expand="false"
          :options="[
            { key: 'none', value: null, label: 'Status' },
            {
              key: 'completed',
              value: { 'filter[order_status]': 'Selesai' },
              label: 'Selesai',
            },
            {
              key: 'follow_up',
              value: { 'filter[order_status]': 'Ada Susulan' },
              label: 'Ada Susulan',
            },
            {
              key: 'not_completed',
              value: { 'filter[not-order_status]': 'Selesai' },
              label: 'Belum Selesai',
            },
          ]"
          v-model="filter.status"
          @change="loadOrders"
        />
        <base-button :to="{ name: 'pembelian.tambah' }"
          >Tambah Pembelian</base-button
        >
      </div>
      <datatable
        :scroll-x="false"
        :columns="columns"
        :is-empty="!orders.data.length"
        :total="orders.meta.page.total"
        :perPage="orders.meta.page.perPage"
        :currentPage="orders.meta.page.currentPage"
        :meta="orders.meta"
        cursor
        @pagechanged="onPageChanged"
      >
        <template #tbody="{ classes }">
          <tbody :class="classes.tbody">
            <tr
              v-for="order in orders.data"
              :key="order.id"
              :class="[classes.tr, 'cursor-pointer hover:bg-green-100']"
              @click="onClickDetail(order)"
            >
              <td :class="classes.td">
                <p class="font-bold text-gray-900">
                  {{ order.attributes.origin_code }}
                </p>
                <p class="text-sm text-gray-500">
                  {{ order.attributes.updatedAt | formatDate }}
                </p>
              </td>
              <td :class="classes.td">
                {{
                  getSingleIncluded(orders, order.relationships.order.data.id)
                    .attributes.origin_office_code
                }}
              </td>
              <td :class="[classes.td, 'text-right']">
                {{
                  getSingleIncluded(orders, order.relationships.order.data.id)
                    .attributes.final_price | toCurrency
                }}
              </td>
              <td
                :class="[
                  classes.td,
                  'text-right',
                  getPaymentRemainder(order) < 0
                    ? 'text-red-500'
                    : 'text-green-500',
                ]"
              >
                <p class="font-bold">
                  {{ getPaymentRemainder(order) | toCurrency }}
                </p>
              </td>
              <td :class="[classes.td, 'text-right']">
                {{
                  getSingleIncluded(orders, order.relationships.order.data.id)
                    .attributes.total_point_value | toCurrency
                }}
              </td>
              <td :class="[classes.td, 'text-right']">
                {{
                  getSingleIncluded(orders, order.relationships.order.data.id)
                    .attributes.total_bonus_value | toCurrency
                }}
              </td>
              <td :class="[classes.td, 'text-center']">
                <base-badge
                  :color="
                    getSingleIncluded(orders, order.relationships.order.data.id)
                      .attributes.has_bundle
                      ? 'green'
                      : 'yellow'
                  "
                  >{{
                    getSingleIncluded(orders, order.relationships.order.data.id)
                      .attributes.has_bundle
                      ? 'Sudah Dilaporkan'
                      : 'Belum Dilaporkan'
                  }}</base-badge
                >
              </td>
              <td :class="[classes.td, 'text-center']">
                <base-badge
                  :color="
                    order.attributes.order_status === 'Draft'
                      ? 'yellow'
                      : 'green'
                  "
                  >{{ order.attributes.order_status }}</base-badge
                >
              </td>
            </tr>
          </tbody>
        </template>
      </datatable>
    </div>

    <view-order-modal
      label="Pembelian"
      :visible="detailModal.visible"
      :order-id="detailModal.id"
      type="purchase"
      :order-detail-columns="['code', 'qty', 'price', 'total_price', 'received_qty', 'unpacked_qty']"
      @close="detailModal.visible = false"
      @deleted="onDeleted"
      @validated="onValidated"
      edit-route-name="pembelian.edit"
    />
  </base-wrapper>
</template>

<script>
import { requestMixin } from '@/mixins/request/request';
import { mapGetters } from 'vuex';
import ViewOrderModal from '@/components/order/view-order-modal.vue';

export default {
  mixins: [requestMixin],
  components: { ViewOrderModal },
  data() {
    return {
      detailModal: {
        visible: false,
        id: null,
      },
      filter: {
        origin_office_category_id: null,
        status: null,
      },
      loading: false,
      orders: {
        data: [],
        meta: {
          page: {},
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      me: 'auth/getUser',
    }),
    columns() {
      return [
        { id: 'code', name: 'Nomor Pembelian' },
        { id: 'office_code', name: 'Kode Kantor' },
        { id: 'final_price', name: 'Total Harga', theadClass: 'text-right' },
        { id: 'payment', name: 'Pembayaran', theadClass: 'text-right' },
        { id: 'point', name: 'PV', theadClass: 'text-right' },
        { id: 'bonus', name: 'BV', theadClass: 'text-right' },
        {
          id: 'order_status',
          name: 'Status Laporan',
          theadClass: 'text-center',
        },
        {
          id: 'warehouse_status',
          name: 'Status Faktur',
          theadClass: 'text-center',
        },
      ];
    },
  },
  methods: {
    getPaymentRemainder(simpleOrder) {
      const order = this.getSingleIncluded(
        this.orders,
        simpleOrder.relationships.order.data.id
      );

      return order.attributes.payment_amount - order.attributes.grand_total_price;
    },
    async loadOrders(params = {}) {
      this.loading = true;

      const [res, err] = await this.request('/api/v1/orders', {
        params: {
          'page[limit]': 5,
          include: 'order',
          'fields[simple-orders]': 'origin_code,updatedAt,order_status,order',
          'fields[orders]':
            'origin_office_code,final_price,grand_total_price,payment_amount,total_point_value,total_bonus_value,has_bundle,order_status',
          'filter[destination_warehouse_id]': this.me.current_warehouse,
          'filter[origin_office_category_id]':
            this.filter.origin_office_category_id,
          ...this.filter.status,
          ...params,
        },
      });

      if (!err) {
        this.orders = res;
      }

      this.loading = false;
    },
    onClickDetail(order) {
      this.detailModal.id = order.relationships.order.data.id;
      this.detailModal.visible = true;
    },
    onDeleted() {
      this.detailModal.visible = false;
      this.loadOrders()
    },
    onValidated() {
      this.detailModal.visible = false;
      this.loadOrders()
    },
    onPageChanged(page) {
      this.loadOrders(page);
    },
  },
  created() {
    this.loadOrders();
  },
};
</script>
