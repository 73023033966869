<template>
  <base-wrapper :loading="loading">
    <div class="space-y-4">
      <div class="flex justify-end gap-2">
        <base-select
          :options="filterStatusOptions"
          :shadow="false"
          v-model="filter.is_received"
          @change="loadStockMovements"
        />
        <base-input
          type="search"
          placeholder="Cari Kode atau Nama"
          :shadow="false"
          debounce
          v-model="filter.search"
          @native-input="loadStockMovements"
        />
      </div>

      <datatable
        :total="stockMovements.meta.page.total"
        :perPage="stockMovements.meta.page.perPage"
        :currentPage="stockMovements.meta.page.currentPage"
        :meta="stockMovements.meta"
        :columns="tableColumns"
        @pagechanged="onChangePage"
        cursor
      >
        <template #tbody="{ classes }">
          <tr
            v-for="stockMovement in stockMovements.data"
            :key="stockMovement.id"
            :class="[classes.tr, 'cursor-pointer bg-white hover:bg-green-100']"
            @click="onClickDetail(getStockMovementIncluded(stockMovement))"
          >
            <td :class="classes.td">
              <p class="font-bold text-gray-900">
                {{ stockMovement.attributes.code }}/{{
                  getStockMovementIncluded(stockMovement).attributes.order_code
                }}
              </p>
              <span class="text-xs text-gray-500">{{
                stockMovement.attributes.updatedAt | formatDate
              }}</span>
            </td>
            <td :class="[classes.td, 'text-right']">
              {{
                Object.keys(
                  getStockMovementIncluded(stockMovement).attributes
                    .product_summaries
                ).length | toCurrency
              }}
            </td>
            <td :class="[classes.td, 'text-right']">
              {{
                countStockMovementWeight(
                  getStockMovementIncluded(stockMovement)
                ) | toCurrency
              }}
            </td>
            <td :class="[classes.td, 'text-right']">
              {{
                countStockMovementPrice(getStockMovementIncluded(stockMovement))
                  | toCurrency
              }}
            </td>
            <td :class="[classes.td, 'text-center']">
              <base-badge
                :color="
                  stockMovement.attributes.is_received ? 'green' : 'yellow'
                "
                >{{
                  stockMovement.attributes.is_received
                    ? 'Diterima'
                    : 'Belum Diterima'
                }}</base-badge
              >
            </td>
            <td :class="[classes.td, 'text-center']">
              <base-badge
                :color="
                  stockMovement.attributes.is_shipped ? 'green' : 'yellow'
                "
                >{{
                  stockMovement.attributes.is_shipped
                    ? 'Dikirim'
                    : 'Belum Dikirim'
                }}</base-badge
              >
            </td>
          </tr>
        </template>
      </datatable>
    </div>

    <view-stock-movement-modal
      :with-validation="false"
      :columns="[]"
      with-actions
      :visible="detailModal.visible"
      :stock-movement-id="detailModal.stockMovementId"
      @close="detailModal.visible = false"
      @received="onReceived"
      @complained="onComplained"
    />
  </base-wrapper>
</template>

<script>
import { requestMixin } from '@/mixins/request/request';
import { mapGetters } from 'vuex';
import { sum } from '@/utils/array';
import ViewStockMovementModal from '@/components/stock-movement/view-stock-movement-modal.vue';

export default {
  mixins: [requestMixin],
  components: { ViewStockMovementModal },
  data() {
    return {
      detailModal: {
        stockMovementId: null,
        visible: false,
      },
      filter: {
        is_received: false,
        search: null,
      },
      loading: false,
      stockMovements: {
        meta: {
          page: {},
        },
        data: [],
      },
    };
  },
  computed: {
    ...mapGetters({
      me: 'auth/getUser',
    }),
    filterStatusOptions() {
      return [
        {
          key: 'null',
          value: null,
          label: 'Pilih Status',
        },
        {
          key: 'true',
          value: true,
          label: 'Diterima',
        },
        {
          key: 'false',
          value: false,
          label: 'Belum Diterima',
        },
      ];
    },
    tableColumns() {
      return [
        { id: 'code', name: 'Nomor Kemasan' },
        { id: 'product_qty', name: 'Total Produk', theadClass: 'text-right' },
        { id: 'total_weight', name: 'Total Berat', theadClass: 'text-right' },
        { id: 'total_price', name: 'Total Harga', theadClass: 'text-right' },
        { id: 'status', name: 'Status', theadClass: 'text-center' },
        {
          id: 'shipment_status',
          name: 'Status Pengiriman',
          theadClass: 'text-center',
        },
      ];
    },
  },
  methods: {
    countStockMovementWeight(stockMovement) {
      if (!stockMovement.attributes.packets) {
        return 0;
      }

      return sum(stockMovement.attributes.packets, (packet) => {
        return sum(packet.products, (product) => product.total_weight);
      });
    },
    countStockMovementPrice(stockMovement) {
      if (!stockMovement.attributes.packets) {
        return 0;
      }

      return sum(stockMovement.attributes.packets, (packet) => {
        return sum(packet.products, (product) => product.total_price);
      });
    },
    getStockMovementIncluded(simpleStockMovement) {
      return this.getSingleIncluded(
        this.stockMovements,
        simpleStockMovement.relationships['stock-movement'].data.id
      );
    },
    async loadStockMovements(params) {
      this.loading = true;

      const [res, err] = await this.request('/api/v1/stock-movements', {
        params: {
          'page[limit]': 5,
          'filter[origin_office_category_id]': 1,
          'filter[destination_office_category_id]': 4,
          'filter[destination_office_id]': this.me.office_id,
          include: 'stock-movement',
          'fields[simple-stock-movements]':
            'code,updatedAt,is_shipped,is_received,stock-movement',
          'fields[stock-movements]': 'order_code,product_summaries,packets',
          'filter[is_shipped]': true,
          'filter[is_received]': this.filter.is_received,
          'filter[search]': this.filter.search,
          ...params,
        },
      });

      if (!err) {
        this.stockMovements = res;
      }

      this.loading = false;
    },
    onChangePage(page) {
      this.loadStockMovements(page);
    },
    onClickDetail(stockMovement) {
      this.detailModal.stockMovementId = stockMovement.id;
      this.detailModal.visible = true;
    },
    onReceived() {
      this.detailModal.visible = false;

      this.loadStockMovements();
    },
    onComplained() {
      this.detailModal.visible = false;

      this.loadStockMovements();
    },
  },
  created() {
    this.loadStockMovements();
  },
};
</script>
